interface VideoReview {
  url: string;
  title: string;
  description: string;
  name: string;
}

export const videoReviews: VideoReview[] = [
  {
    url: 'https://res.cloudinary.com/drfweekiv/video/upload/v1724060140/na-1.mp4',
    title: 'My business is going so well',
    description: `"My birth chart showed I had the potential for financial success. It encouraged me to start my own business. Now, it’s going so well!"`,
    name: 'Haley F.',
  },
  {
    url: 'https://res.cloudinary.com/drfweekiv/video/upload/v1724060145/na-4.mp4',
    title: 'Learned so much!',
    description: `"I’ve always loved crystals, but now I feel like I have a much clearer understanding of how to use them effectively for my goals."`,
    name: 'Skyler B.',
  },
  {
    url: 'https://res.cloudinary.com/drfweekiv/video/upload/v1724060141/na-3.mp4',
    title: 'Nordastro motivated me',
    description: `"As a cancer, I appreciate how the book recognized my creative nature. It inspired me to start a blog that’s now gaining traction."`,
    name: 'Julia S.',
  },
  {
    url: 'https://res.cloudinary.com/drfweekiv/video/upload/v1724060140/na-2.mp4',
    title: 'I built my confidence',
    description: `"I've struggled with confidence all my life. Discovering my strengths and feminine energy helped me build confidence as a proud Leo."`,
    name: 'Sarah J.',
  },
  {
    url: 'https://res.cloudinary.com/drfweekiv/video/upload/v1724060142/na-5.mp4',
    title: 'Feeling hopeful again',
    description: `"After a painful breakup, I was feeling miserable. Nordastro gave me hope that love is just around the corner. I feel worthy again."`,
    name: 'Ruby P.',
  },
];
