import React, { FC } from 'react';
import styled from 'styled-components';
import { useRouter } from 'apis/history';

interface CtaButtonProps {
  buttonText: string;
}

const CtaButton: FC<CtaButtonProps> = ({ buttonText }) => {
  const { goToLanding } = useRouter();

  return (
    <ButtonContainer onClick={() => goToLanding()}>
      <StyledCTA>{buttonText}</StyledCTA>
    </ButtonContainer>
  );
};

export default CtaButton;

const ButtonContainer = styled.div`
  width: 100%;
`;

const StyledCTA = styled.button`
  font-size: 18px;
  color: #000;
  width: 100%;
  min-height: 56px;
  font-weight: 700;
  border-radius: 100px;
  border: none;
  cursor: pointer;
  background: #f7c800;
  font-family: Manrope;
  box-shadow: 0px 2px 4px 0px rgba(96, 97, 112, 0.16);
`;
