import React, { FC } from 'react';
import {
  SectionHeading,
  SectionSubheading,
  StyledSection,
} from 'pages/reviews';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import { tablet, useQuery } from 'styles/breakpoints';
import { SuccessStories } from 'utils/successStories';
import SuccessCard from './SuccessCard';
import styled from 'styled-components';
import CtaButton from './CtaButton';

const SuccessStoriesSection: FC = () => {
  const { isTablet } = useQuery();

  return (
    <StyledSection
      backgroundColor="#fff"
      sectionPadding="56px 16px"
      marginTop="0px"
    >
      <SectionHeading marginBottom="4px">
        Stories from our community
      </SectionHeading>
      <SectionSubheading marginBottom={isTablet ? '20px' : '38px'}>
        Read about our customers’ journey
      </SectionSubheading>
      <CarouselContainer>
        <SwiperStyled
          modules={[Pagination]}
          pagination={{ clickable: true }}
          allowTouchMove={true}
          effect="slide"
          slidesPerView={1}
          spaceBetween={isTablet ? 15 : 22}
          breakpoints={{
            768: {
              slidesPerView: 2,
            },
            1110: {
              slidesPerView: 3,
            },
          }}
        >
          {SuccessStories.map((story, index) => (
            <SwiperSlide key={index}>
              <SuccessCard
                imgUrl={story.imgUrl}
                author={story.author}
                story={story.story}
              />
            </SwiperSlide>
          ))}
        </SwiperStyled>
      </CarouselContainer>
      <ButtonContainer>
        <CtaButton buttonText="Start your journey" />
      </ButtonContainer>
    </StyledSection>
  );
};

export default SuccessStoriesSection;

const CarouselContainer = styled.div`
  width: 100%;
  max-width: 1080px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 768px) and (max-width: 1110px) {
    max-width: 710px;
  }
`;

const SwiperStyled = styled(Swiper)`
  .swiper-pagination {
    position: relative !important;
    width: 100%;
  }

  .swiper-slide {
    display: flex;
    justify-content: center;
    margin-bottom: 28px;

    @media ${tablet} {
      margin-bottom: 24px;
    }
  }

  .swiper-pagination-bullet {
    opacity: unset;
    margin: 0 0.25rem;
    background-color: rgba(115, 137, 156, 0.5);
    &-active {
      background-color: #f7c800;
      width: 8px;
    }
  }
`;

const ButtonContainer = styled.div`
  max-width: 343px;
  width: 100%;
  margin-top: 16px;

  @media ${tablet} {
    margin-top: 8px;
  }
`;
