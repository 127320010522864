interface UserReview {
  author: string;
  comment: string;
}

export const userReviews: UserReview[] = [
  {
    author: 'Violet J.',
    comment:
      'Love the app and the book combo. The book is really informative, and SO PRETTY!',
  },
  {
    author: 'Samantha',
    comment:
      'At first I was a bit skeptical, but after reading it, I found so many traits and other things that are 1000% about ME.',
  },
  {
    author: 'Claire B.',
    comment: `Love the astrological guidance. I’m always excited to read the daily horoscopes on the app to prepare for the day.`,
  },
  {
    author: 'Laurel W.',
    comment:
      'Everything I have read in this book is shockingly accurate. Highly recommend this book for yourself or as a gift.',
  },
  {
    author: 'Rose K.',
    comment:
      'A book I can\'t put down. I must say it feels like they wrote everything just about me. Super accurate!',
  },
  {
    author: 'Saphire L.',
    comment:
      'Their detailed analysis of my birth chart revealed patterns I hadn\'t noticed before. I’m getting to know myself better.',
  },
];
