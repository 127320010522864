interface SuccessStory {
  imgUrl: string;
  story: string;
  author: string;
}

export const SuccessStories: SuccessStory[] = [
  {
    imgUrl:
      'https://res.cloudinary.com/drfweekiv/image/upload/v1724065626/Frame_1214134128_26.png',
    story: `"This book blew me away with the accuracy... Nordastro helped me realize and overcome toxic friendships that were dragging me down for years. I did a full 180 since I got this book. I feel so much lighter and more positive now."`,
    author: 'Victoria, 28',
  },
  {
    imgUrl:
      'https://res.cloudinary.com/drfweekiv/image/upload/v1724065625/Frame_1214134128_27.png',
    story:
      '"As a Scorpio, I often find astrology books to be hit or miss, but this one is super accurate. After a painful breakup last month, the advice on self-reflection and moving forward has given me the strength to rebuild my confidence."',
    author: 'Chloe,  26',
  },
  {
    imgUrl:
      'https://res.cloudinary.com/drfweekiv/image/upload/v1724065625/Frame_1214134128_28.png',
    story: `"It's uncanny how spot-on it is. The book predicts a huge romantic event at the end of this summer, which aligns perfectly with what I’ve been manifesting. My crush and I have been getting closer, and I really hope this prediction comes true."`,
    author: 'Jessica, 27',
  },
  {
    imgUrl:
      'https://res.cloudinary.com/drfweekiv/image/upload/v1724065626/Frame_1214134128_29.png',
    story:
      '"The book predicted I would find love in May after feeling so alone for the past 2 years. Amazingly, my manifestations worked, and I’m now celebrating my 6 months anniversary with my long-time crush!"',
    author: 'Emily C., 25',
  },
  {
    imgUrl:
      'https://res.cloudinary.com/drfweekiv/image/upload/v1724065628/Frame_1214134128_30.png',
    story:
      '"As a tarot lover, Nordastro deepened my understanding of the cards. A couple of months ago, I did a reading for my best friend applying for her dream job. Not only did she land the job, but she was also offered a bigger salary within a month!"',
    author: 'Viv, 31',
  },
  {
    imgUrl:
      'https://res.cloudinary.com/drfweekiv/image/upload/v1724065626/Frame_1214134128_31.png',
    story:
      '"I got this book for my birthday, and it’s the most gorgeous gift I’ve received. It not only includes a detailed analysis of my birth chart, but also has informative chapters about palmistry, tarot cards, numerology, and astrology in general."',
    author: 'Sarah, 27',
  },
];
